<template>
  <div class="goods_slide">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="img in images" :key="img.view.image_seq">
        <img
          :src="img.view.image"
          @error="replaceImg"
          style="width: calc(100% / 1.5)"
        />
      </swiper-slide>
    </swiper>
    <div class="swiper-pagination" id="pagination"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next"></div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  props: {
    images: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      swiperOption: {
        initialSlide: 0,
        slidesPerView: 1,
        loop: true,
        pagination: { el: ".swiper-pagination", clickable: true },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
    };
  },
  components: { Swiper, SwiperSlide },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
  },
};
</script>

<style lang="scss">
.goods_slide {
  .swiper {
    padding: 0 0 35px 0;
    .swiper-slide {
      text-align: center;
    }
  }

  .swiper-pagination {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 25px;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }

  .swiper-pagination-bullet {
    background: url("~@/assets/images/sub/banner_off.png") no-repeat;
    background-size: 15px 15px;
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-left: 3px;
    opacity: 1;
  }

  .swiper-pagination-bullet-active {
    background: url("~@/assets/images/sub/banner_on.png") no-repeat;
    background-size: 15px 15px;
  }
  .swiper-button-next {
    background: url("~@/assets/images/sub/slide_right.png") no-repeat;
    background-position: top;
    background-size: 18px 24px;
    right: 0px;
  }

  .swiper-button-prev {
    background: url("~@/assets/images/sub/slide_left.png") no-repeat;
    background-position: top;
    background-size: 18px 24px;
    left: 0px;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: none;
  }
}
</style>
